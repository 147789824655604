@font-face {
    font-family: 'sl_pixlregular';
    src: url('./assets/sl_pixl_regular_v1-webfont.woff2') format('woff2'),
         url('./assets/sl_pixl_regular_v1-webfont.woff') format('woff'),
         url('./assets/sl_pixl_regular_v1-webfont.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pt_sansbold';
    src: url('./assets/ptsans-bold-webfont.woff2') format('woff2'),
         url('./assets/ptsans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pt_sansbold_italic';
    src: url('./assets/ptsans-bolditalic-webfont.woff2') format('woff2'),
         url('./assets/ptsans-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pt_sansitalic';
    src: url('./assets/ptsans-italic-webfont.woff2') format('woff2'),
         url('./assets/ptsans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'pt_sansregular';
    src: url('./assets/ptsans-regular-webfont.woff2') format('woff2'),
         url('./assets/ptsans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}